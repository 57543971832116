<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="PROCESO DE CREMACIÓN"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div
          class="field col-12 md:col-12"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <h2 style="color: #003a6e; font-weight: bold; margin-bottom: 0px">
            Formulario de información sobre el proceso de cremación
          </h2>
          <span style="color: #003a6e; font-weight: bold; margin-bottom: 0px"
            >(Llenado por {{ auth.user.name }} )</span
          >
        </div>

        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">FECHA_DEL_SERVICIO:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.fecha_ventas"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">HORA_DE_INICIO:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.hora_inicio"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">TIEMPO_TRANSCURRIDO:</span>
            <span class="p-float-label">
              <Textarea
                v-model="tiempo_transcurrido"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">CERTIFICADO_NRO.:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.certificado"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">FACTURA_NRO.:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.factura"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">OBSERVACIONES:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.observacion"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>

        <div class="field col-12 md:col-6">
          <hr
            style="
              border: 1px solid #003a6e;
              border-radius: 5px;
              margin-top: 20px;
              margin-bottom: 0px;
            "
          />
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">RECIBIDO_Bs.:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.bs"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">RECIBIDO_$us.:</span>
            <span class="p-float-label">
              <Textarea
                v-model="data_proceso_cremacion.us"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        :loading="enviado"
        @click="guardarProcesoCremacion"
      />
    </template>
  </Dialog>
</template>
<script>
import VentaService from "@/service/VentaService";
import { useAuth } from "@/stores";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procesoCremacion: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  ventaService: null,
  auth: null,
  data() {
    return {
      mostrarModal: this.show,
      data_proceso_cremacion: this.procesoCremacion,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tiempo_transcurrido: 0,
      venta: {},
      venta_id: this.$route.params.id,
      productos: [],
      ventaService: null,
    };
  },

  created() {
    this.ventaService = new VentaService();
    this.auth = useAuth();
    //this.cargarDatosIniciales();
  },
  mounted() {
    this.cargarDatosIniciales();
  },
  methods: {
    cargarDatosIniciales() {
     
      let proceso_cremacion_enviar2 = {
        ...this.data_proceso_cremacion,
        venta_id: this.data_proceso_cremacion.venta_id,
        fecha_cremacion: this.data_proceso_cremacion.fecha_cremacion,

      };
    console.log(proceso_cremacion_enviar2.venta_id,proceso_cremacion_enviar2.fecha_cremacion);
      
    
    },
    goBack() {
      this.$router.go(-1);
    },
    guardarProcesoCremacion() {
      let proceso_cremacion_enviar = {
        ...this.data_proceso_cremacion,
        estado: this.data_proceso_cremacion.estado.value,
        hora_inicio: this.data_proceso_cremacion.hora_inicio,
      };

      this.ventaService
        .guardarProcesoCremacion(proceso_cremacion_enviar)
        .then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.imprimirCertificado(data.venta_id);
            this.imprimirSolicitud(data.venta_id);
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
    },
    imprimirCertificado(id) {
      this.ventaService
        .imprimirCertificado(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    imprimirSolicitud(id) {
      this.ventaService
        .imprimirSolicitud(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarCliente() {
      this.errors = {};
      this.enviado = true;
      //editar procesoCremacion
      if (this.procesoCremacion.id) {
        let proceso_cremacion_enviar = {
          ...this.data_proceso_cremacion,
          estado: this.data_proceso_cremacion.estado.value,
        };
        this.ventaService
          .updatedCliente(proceso_cremacion_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_proceso_cremacion;
        this.data_proceso_cremacion = {
          ...this.data_proceso_cremacion,
          estado: this.data_proceso_cremacion.estado.value,
        };
        this.ventaService
          .sendClienteNuevo(this.data_proceso_cremacion)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_proceso_cremacion = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.procesoCremacion);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },

    procesoCremacion(val) {
      this.data_proceso_cremacion = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
</style>
